import { PageProps, graphql } from "gatsby";
import React from "react";

import FaqsCategories from "@components/faq/faqsCategories";
import { BlocksContent } from "@components/global";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { Container } from "@util/standard";
import Layout from "@shared/layout";

interface Props extends PageProps {
  data: Query;
}

function Faq({ data }: Props) {
  if (data == null || data.sanityFaqsPage == null) {
    return null;
  }

  const { faqsHero, seo, categoryPreview } = data.sanityFaqsPage;
  const faqCategories = data.allSanityFaqCategories.nodes;
  return (
    <div>
      <SEO seoData={seo} />
      <Layout>
        <Container
          width="65%"
          tabletWidth="80%"
          margin="220px auto 140px auto"
          tabletMargin="220px auto 40px auto"
          flexDirection="column"
        >
          {faqsHero && <BlocksContent blocks={faqsHero?._rawColumnContent} />}
          {faqCategories && (
            <Container margin="40px auto" width="100%">
              <FaqsCategories data={faqCategories} />
            </Container>
          )}
        </Container>
        {categoryPreview && <LearnCategoryPreview data={categoryPreview} />}
      </Layout>
    </div>
  );
}

export default Faq;

export const query = graphql`
  {
    sanityFaqsPage {
      seo {
        ...sanitySeo
      }
      faqsHero {
        ...sanityColumn
      }
      categoryPreview {
        ...sanityLearnCategoryPreview
      }
    }
    allSanityFaqCategories {
      nodes {
        categoryName
        _id
        _type
        questions {
          _key
          question
          answer {
            ...sanityColumn
          }
        }
      }
    }
  }
`;
