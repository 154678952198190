import React, { useState } from "react";
import styled from "styled-components";

import { BlocksContent } from "@components/global";
import Accordion from "@components/global/accordion";
import { SanityFaqCategories } from "@graphql-types";
import { colorsRGB, colors } from "@util/constants";
import { Container, P } from "@util/standard";

interface Props {
  data: SanityFaqCategories[];
}

const CategoryWrapper = styled(Container) <{ highlighted?: boolean; }>`
  background: ${colorsRGB.darkerGreen(0.2)};
  border-radius: 3px;
  margin: 0 8px 8px 0;
  ${({ highlighted }) => (highlighted ? `opacity: 1;` : `opacity: 0.4;`)};

  transition: all 0.3s;
`;

const Category = styled(P)`
  color: ${colors.darkerGreen};
  cursor: pointer;
  font-size: 13px;
  padding: 7px 12px;
  margin: auto;
`;

const BlocksContentContainer = styled(Container)`
  p{
    margin-bottom: 30px;
  }
`;

function FaqsCategories({ data }: Props) {
  if (data == null) {
    return null;
  }

  const [currentCategory, setCurrentCategory] = useState(data[0]);

  return (
    <Container flexDirection="column" width="100%">
      <Container margin="0 0 80px 0">
        {data.map(category => {
          if (category == null || category.categoryName == null) {
            return null;
          }

          return (
            <CategoryWrapper
              highlighted={currentCategory.categoryName === category.categoryName}
              onClick={() => setCurrentCategory(category)}
              key={category._id}
            >
              <Category>{category.categoryName}</Category>
            </CategoryWrapper>
          );
        })}
      </Container>
      {currentCategory && currentCategory.questions && (
        <Container flexDirection="column">
          {currentCategory.questions.map((question, index) => {
            if (question == null || question.answer == null) {
              return null;
            }

            const isLast = index === currentCategory?.questions?.length - 1;

            return (
              <Accordion
                borderColor="black"
                noBorderBottom={!isLast}
                text={question.question ? question?.question : "Question"}
                key={question._id}
              >
                <BlocksContentContainer>
                  <BlocksContent blocks={question?.answer?._rawColumnContent} />
                </BlocksContentContainer>
              </Accordion>
            );
          })}
        </Container>
      )}
    </Container>
  );
}

export default FaqsCategories;
